import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles, TextField } from "@material-ui/core";
import '../styles/popup.css';

const useStyles = makeStyles((theme) => ({
    MuiSelect: {
        backgroundColor: 'transparent',
    },
    transparentInput: {
        backgroundColor: 'transparent',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
        }
    },
    dateField: {
        width: '100%',
        '& input': {
            backgroundColor: 'white',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.primary.main}`,
            padding: '10px',
        },
        '&:focus-within input': {
            borderColor: theme.palette.primary.main,
        },
    },

    customInput: {
        width: '100%',
        height: '2rem',
        '& input': {
            padding: '18.5px 14px !important', 
        },
    },
}));
const CustomDatePickerNew = ({
    selectedDate,
    handleDateChange,
    readOnly = false,
    placeholder = "MM/DD/YYYY",
    name,
    error = undefined,
    minDate = undefined,
    maxDate = undefined,
    fullWidth = true,
}) => {
    const classes = useStyles();

    const parsedDate = selectedDate ? new Date(selectedDate) : null;
    const parseMaxDate = maxDate ? new Date(maxDate) : undefined;
    const parseMinDate = minDate ? new Date(minDate) : undefined;

    const dateHandler = (date: Date) => {
        if (date) {
            const formattedDate = date.toISOString();
            handleDateChange(formattedDate);
        } else {
            handleDateChange(null);
        }
    }

    return (
        <div style={{ width: fullWidth ? "100%" : "auto" }}>
            <DatePicker
                selected={parsedDate}
                onChange={dateHandler}
                minDate={parseMinDate}
                maxDate={parseMaxDate}
                showIcon
                showWeekNumbers
                dateFormat="MM/dd/yyyy"
                placeholderText={placeholder}
                shouldCloseOnSelect={true}
                fixedHeight
                customInput={
                    <TextField
                        fullWidth={fullWidth}
                        name={name}
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        InputLabelProps={{ shrink: true }}
                        className={classes.customInput}
                    />
                }
            />
        </div>
    );
};

export default CustomDatePickerNew;