import { Dialog, DialogContent, DialogTitle, Typography, Grid, TextField, FormControl, Button, DialogActions, Select, InputLabel, MenuItem, InputAdornment, FormControlLabel, Checkbox, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import { formatFileUrl } from "../../../Utilities/Formatting";
import ImagePreviewDialog from '../ImagePreviewDialog/ImagePreviewDialog'
import no_image from '../../../Images/no_image.png'
import { COUNTRIES, COUNTRY_INSURANCES, STATUS, TOKEN_STORAGE_KEY, VEHICLE_TYPES_BY_COUNTRY } from "../../../Types/Constants";
import { useCallback, useEffect, useState } from "react";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { Driver } from "../../../Api/Driver/Driver";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IDriverUpdatePayload } from "../../../../../Application/DTOs/DriverDto/DriverDto.types";
import WarningDialog from '../WarningDialog/WarningDialog'
import { getLabelForRegistrationNumberByCountry } from '../../../Utilities/Country'

import { useSelector } from 'react-redux';
import { AppState } from '../../../Redux';
import { USER_ROLES } from '../../../Types/Constants'
import { IDetailsCarrierProps } from "./CarrierDetailsDialog.types";
import { ICarrierInfoPayload } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { WebStorage } from "../../../Utilities/WebStorage";
import { Carrier } from "../../../Api/Carrier/Carrier";
import CustomDatePicker from "../../../ComponentsV2/ui/CustomDatePicker";
 
const baseUrl = process.env.REACT_APP_NEW_BASE_URL;

const carrierApi = new Carrier()

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    gap: {
        marginTop: theme.spacing(3)
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    }
}))

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const {
    ACTIVE,
    INACTIVE
} = STATUS

const {
    SUPER_ADMIN,
    ADMIN_USER,
    COMPANY,
    COMPANY_DRIVER,
    DRIVER
} = USER_ROLES

function CarrierDetialsDialog(props: IDetailsCarrierProps) {
    const [t] = useTranslation('CarrierDetailsDialog')
    const classes = useStyles()
    const dispatch = useDispatch()
    const { open, handleClose, selectedUserId, hideActions, deleteUserId , setIsDriverDetailsDialogOpen} = props
    
    const user = useSelector((state: AppState) => state.user)
    const isSuperAdmin: boolean = [SUPER_ADMIN].includes(user.response?.type ?? '')
    const isUserAdmin: boolean = user.response?.type === ADMIN_USER
    const isCompany = user.response?.type === COMPANY

    const [country, setCountry] = useState(AUSTRALIA)
    const [email, setEmail] = useState('')
    const [userStatus, setUserStatus] = useState('')
    const [mobile, setMobile] = useState('')
    const [title, setTitle] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyTradingName, setCompanyTradingName] = useState('')
    const [companyServiceProvde, setCompanyServiceProvide] = useState('')
    const [companyWebsite, setCompanyWebsite] = useState('')
    const [primaryPerson, setPrimaryPerson] = useState('')
    const [companyUEN, setCompanyUEN] = useState('')
    const [streetNameAddress, setStreetNameAddress] = useState('')
    const [city, setCity] = useState('')
    const [officeAddress, setOfficeAddress] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [serviceRegion, setServiceRegion] = useState<any>([])
    const [accountName, setAccountName] = useState('')
    const [accountPhone, setAccountPhone] = useState('')
    const [accountEmail, setAccountEmail] = useState('')
    const [operationsName, setOperationsName] = useState('')
    const [operationsPhone, setOperationsPhone] = useState('')
    const [operationsEmail, setOperationsEmail] = useState('')
    const [isEditMode, setIsEditMode] = useState(false)
    const [CarrierInfo, setCarrierInfo] = useState<IUserInfo | null>(null)
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
    const [imageToPreview, setImageToPreview] = useState<string | undefined>(undefined)
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false)
    const [warehoueLocation, setWareHouseLocation] = useState('')
    const [insurances, setInsurances] = useState<string[]>([])
    const [wareHoueServiceProvide, setWareHoueServiceProvide] = useState('')
    const [selectTypeStorage, setSelectTypeStorage] = useState('')
    const [fleetNumberOfTruck, setFleetNumberOfTruck] = useState('')
    const [fleetMaxLoadCap, setFleetMaxLoadCap] = useState('')
    const [fleetTrackSystem, setFleetTrackSystem] = useState('')
    const [certificationsAccreditations, setCertificationsAccreditations] = useState('');
    const [rateReviewFrequency, setRateReviewFrequency] = useState('');
    const [noticePeriodRatechanges, setNoticePeriodRatechanges] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [vendorBankName, setVendorBankName] = useState('');
    const [vendorBankBsb, setVendorBankBsb] = useState('');
    const [vendorAccountNumber, setVendorAccountNumber] = useState('');
    const [vendorBankAccountName, setVendorBankAccountName] = useState('');
    const [moveItAdminName, setMoveItAdminName] = useState('');
    const [moveItAdminPosition, setMoveItAdminPosition] = useState('');
    const [moveItAdminEmail, setMoveItAdminEmail] = useState('');
    const [moveItAdminPhone, setMoveItAdminPhone] = useState('');
    const [vendorRepresentativeName, setVendorRepresentativeName] = useState('');
    const [vendorRepresentativePosition, setVendorRepresentativePosition] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [error, setError] = useState('');
    const [cleanedRegionsSelect, setCleanedRegionsSelect] = useState([])
    const [totalWareHoueSpace, setTotalWareHoueSpace] = useState('')

    const storage = new WebStorage();
    
    const INSURANCES = COUNTRY_INSURANCES[country] ?? []

    const cleanedRegions = serviceRegion.map((region) => {
        const cleanedRegion = region.replace(/\\+/g, '')
                              .replace(/^"|"$/g, '')
                              .replace(/[^a-zA-Z\s]/g, '');
        return cleanedRegion.trim();
    });

    const handleCheckboxChange = (region: string) => {
        setCleanedRegionsSelect((prevRegions) =>
            prevRegions.includes(region)
                ? prevRegions.filter((r) => r !== region)
                : [...prevRegions, region]
        );
    };

    
    const handleDateChange = (event) => {
        const dateValue = event.target.value;
        setSelectedDate(dateValue);
        if (!dateValue) {
            setError('Date is required');
        } else {
            setError('');
        }
    };


    const handleInsuranceCheck = useCallback(
        (isChecked: boolean, insurance: string) => {
            if (isChecked) {
                if (!insurances.includes(insurance)) {
                    insurances.push(insurance)
                    setInsurances([...insurances])
                }
            } else {
                setInsurances(insurances.filter(ins => ins !== insurance))
            }
        },
        [insurances],
    )


    const handleWarningDialogClose = () => (
        setIsWarningDialogOpen(false)
    )

    const handleEditClick = useCallback(
        () => {
            setIsEditMode(true)
        },
        [],
    )

    const setCarrierDetails = useCallback(
        (carrierInfo: any) => {
            setCountry(carrierInfo.Carrier?.country ?? '')
            setEmail(carrierInfo?.userDetails?.email)
            setUserStatus(carrierInfo?.userDetails?.status)
            setMobile(carrierInfo.directphonenumber)
            setTitle(carrierInfo.title)
            setFirstName(carrierInfo?.userDetails?.first_name)
            setLastName(carrierInfo?.userDetails?.last_name)
            setPrimaryPerson(carrierInfo?.primarycontactperson)
            setCompanyName(carrierInfo?.companyname ?? '')
            setCompanyTradingName(carrierInfo?.tradingname ?? '')
            setCompanyUEN(carrierInfo?.companyabn ?? '')
            setCompanyWebsite(carrierInfo?.companywebsite ?? '')
            setCompanyServiceProvide(carrierInfo.primaryservicesprovided ?? '')
            setStreetNameAddress(carrierInfo?.company_postal_address ?? '')
            setCity(carrierInfo?.addresscity ?? '')
            setPostalCode(carrierInfo?.postcode ?? '')
            setOfficeAddress(carrierInfo?.addressstreet ?? '')
            setServiceRegion(carrierInfo?.service_regions ?? '')
            setAccountName(carrierInfo?.accountcontactname ?? '')
            setAccountEmail(carrierInfo?.accountcontactemail ?? '')
            setAccountPhone(carrierInfo?.accountcontactphone ?? '')
            setOperationsName(carrierInfo?.operationscontactname ?? '')
            setOperationsPhone(carrierInfo?.operationscontactphone ?? '')
            setOperationsEmail(carrierInfo?.operationscontactemail ?? '')
            setWareHouseLocation(carrierInfo?.warehouse_location ?? '')
            setWareHoueServiceProvide(carrierInfo?.warehouse_services_provide ?? '')
            setSelectTypeStorage(carrierInfo?.storage_types ?? '')
            setFleetNumberOfTruck(carrierInfo?.fleet_number_of_trucks ?? '')
            setFleetMaxLoadCap(carrierInfo?.fleet_max_load ?? '')
            setFleetTrackSystem(carrierInfo?.fleet_tracking_system ?? '')
            setCertificationsAccreditations(carrierInfo?.certifications_accreditations ?? '')
            setRateReviewFrequency(carrierInfo?.rate_review_frequency ?? '')
            setNoticePeriodRatechanges(carrierInfo?.notice_period_for_rate_changes ?? '')
            setPaymentTerms(carrierInfo?.payment_terms ?? '')
            setVendorBankName(carrierInfo?.vendor_bank_name ?? '')
            setVendorBankBsb(carrierInfo?.vendor_bank_bsb ?? '')
            setVendorAccountNumber(carrierInfo?.vendor_account_number ?? '')
            setVendorBankAccountName(carrierInfo?.vendor_account_name ?? '')
            setMoveItAdminName(carrierInfo?.moveit_admin_name ?? '')
            setMoveItAdminPosition(carrierInfo?.moveit_admin_position ?? '')
            setMoveItAdminEmail(carrierInfo?.moveit_admin_email ?? '')
            setMoveItAdminPhone(carrierInfo?.moveit_admin_phone ?? '')
            setVendorRepresentativeName(carrierInfo?.vendor_authorised_rep_name ?? '')
            setVendorRepresentativePosition(carrierInfo?.vendor_authorised_rep_position ?? '')
            setSelectedDate(carrierInfo?.vendor_declaration_date ?? '')
            setCleanedRegionsSelect(carrierInfo?.service_regions ?? '')
            setTotalWareHoueSpace(carrierInfo?.total_warehouse_space ?? '')
            
        },
        [],
    )

    const handleCancelClick = useCallback(
        () => {
            if (CarrierInfo) setCarrierDetails(CarrierInfo)
            setIsEditMode(false)
        },
        [CarrierInfo, setCarrierDetails],
    )

    const getApprovedCarrierDetails = useCallback(
        (userId: number | string) => {
            dispatch(setLoader(true))
            carrierApi.getDetails(userId)
                .then(response => {
                    const user: any = response?.data?.data;
                    setCarrierDetails(user)
                    setCarrierInfo(user)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_Carrier_details'))
                })
        },
        [dispatch, t, setCarrierDetails],
    )

    const handleOnImageSelect = useCallback(
        (imageSource: string) => {
            setImageToPreview(imageSource)
            setIsImagePreviewOpen(true)
        },
        [],
    )
    const handleImagePreviewClose = useCallback(
        () => {
            setIsImagePreviewOpen(false)
        },
        [],
    )

    useEffect(() => {
        if (selectedUserId) {
            getApprovedCarrierDetails(selectedUserId)
        }
    }, [selectedUserId, getApprovedCarrierDetails])

    const handleUpdateStatusClick = useCallback(
        () => {
            if (deleteUserId) {
                const statusPayload = {
                    status: userStatus === INACTIVE ? ACTIVE : INACTIVE,
                };
                dispatch(setLoader(true))
                carrierApi.updateStatus(deleteUserId, statusPayload as any)
                    .then((response) => {
                        dispatch(setLoader(false))
                        if (response?.data?.message) {
                            toast.success(response.data.message);
                            setIsDriverDetailsDialogOpen(false);
                        } else {
                            toast.success(t('Carrier status updated successfully'));
                        }

                        if (selectedUserId) {
                            getApprovedCarrierDetails(selectedUserId)
                        }
                    })
                    .catch(err => {
                        console.log('err', err)
                        dispatch(setLoader(false))
                        toast.error(t('error_getting_Carrier_details'))
                    })
            }

        },
        [selectedUserId, CarrierInfo, t, dispatch, getApprovedCarrierDetails],
    )   

    const handleUpdateCarrierClick=()=>{
        if (selectedUserId) {
            const payload: any = {
                first_name: firstName,
                last_name: lastName,
                title: title,
                email: email,
                directphonenumber: mobile,
                accountcontactemail: accountEmail,
                accountcontactname: accountName,
                accountcontactphone: accountPhone,
                operationscontactemail: operationsEmail,
                operationscontactname: operationsName,
                operationscontactphone: operationsPhone,
                // password: "",
                companyname: companyName,
                companyabn: companyUEN,
                tradingname: companyTradingName,
                companywebsite: companyWebsite,
                addresscity: city,
                addresspostalcode: postalCode,
                addressstreet: officeAddress,
                company_postal_address: streetNameAddress,
                primaryservicesprovided: companyServiceProvde,
                primarycontactperson: primaryPerson,
                warehouse_location: warehoueLocation,
                total_warehouse_space: totalWareHoueSpace,
                warehouse_services_provide: wareHoueServiceProvide,
                fleet_number_of_trucks: fleetNumberOfTruck,
                fleet_max_load: fleetMaxLoadCap,
                fleet_tracking_system: fleetTrackSystem,
                // liability_insurance: "",
                storage_types: country,
                // services: [],
                service_regions: cleanedRegions,
                // liability_insurance_file: [],
                // compensation_insurance: "",
                // compensation_insurance_file: [],
                // carriers_liability_insurance: "",
                // carriers_liability_insurance_file: [],
                responsibility_legislation: "",
                // responsibility_legislation_file: [],
                certifications_accreditations: certificationsAccreditations,
                vendor_bank_name: vendorBankName,
                vendor_bank_bsb: vendorBankBsb,
                vendor_account_number: vendorAccountNumber,
                vendor_account_name: vendorBankAccountName,
                // service_regions_internation: "",
                moveit_admin_name: moveItAdminName,
                moveit_admin_position: moveItAdminPosition,
                moveit_admin_email: moveItAdminEmail,
                moveit_admin_phone: moveItAdminPhone,
                vendor_authorised_rep_name: vendorRepresentativeName,
                vendor_authorised_rep_position: vendorRepresentativePosition,
                // vendor_signature: [],
                vendor_declaration_date: selectedDate,
                // rate_sheet_attachment: [],
                rate_review_frequency: rateReviewFrequency,
                notice_period_for_rate_changes: noticePeriodRatechanges,
                payment_terms: paymentTerms
            }

            dispatch(setLoader(true));
        
            carrierApi.update(selectedUserId, payload)
                .then((response) => {
                    dispatch(setLoader(false))
                    if (response?.data?.success) {
                        toast.success(response.data.message);
                        handleWarningDialogClose();
                        getApprovedCarrierDetails(selectedUserId);
                        setIsEditMode(false);
                        setIsDriverDetailsDialogOpen(false);
                    } else {
                        toast.success(t('Carrier updated successfully'));
                    }
                })
                .catch((err :any) => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('Error while updating'))
                })

            }
    }

    const handleOnDeleteClick = useCallback(
        () => {
            setIsWarningDialogOpen(true)
        },
        [],
    )

    const handleOnProceedDeleteClick =  useCallback(
        async () => {
            if (deleteUserId) {
                try {
                    const response = await fetch(`${baseUrl}/v1/delete/carrier/carrier/delete`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
                        },
                        body: JSON.stringify({
                            user_id: deleteUserId, 
                        }),
                    });
            
                    if (!response.ok) {
                        throw new Error('Failed to delete carrier');
                    }
                  
                    const responseData = await response.json();

                    if (responseData?.message) {
                        toast.success(responseData.message);
                        handleWarningDialogClose();
                        setIsDriverDetailsDialogOpen(false);
                    } else {
                        toast.success('Carrier deleted successfully');
                        setIsDriverDetailsDialogOpen(false);                    }
                    if (responseData?.status == 404) {
                        toast.error(responseData.message);
                        setIsDriverDetailsDialogOpen(false);
                    }
                } catch (error) {
                    console.error('Error deleting carrier:', error);
                    toast.error(error?.message || 'No associated carrier found for the given user');
                }
            }
        },
        [deleteUserId, dispatch, t, handleClose, handleWarningDialogClose]
    )

    return (
        <>
            <WarningDialog onProceedClick={handleOnProceedDeleteClick} open={isWarningDialogOpen} handleClose={handleWarningDialogClose} message={t('Want to delete this carrier record? If you delete this record, it will not be recoverable in the future')} />
            <ImagePreviewDialog imageSource={imageToPreview} open={isImagePreviewOpen} handleClose={handleImagePreviewClose} />
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                        {`Carrier Details`}
                        </Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        {/* <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('country')}</InputLabel>
                                <Select
                                    disabled={!isEditMode}
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value as string)
                                        setVehicleType('')
                                    }}
                                >
                                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('mobile_number')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('title')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('first_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('last_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                      
                        {/* {
                            isSuperAdmin || isUserAdmin
                                ? <Grid md={6} item>
                                    <TextField
                                        disabled={!isEditMode}
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('company_name')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <TextFieldsIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                : null

                        } */}

                                <Grid md={6} item>
                                    <TextField
                                        disabled={!isEditMode}
                                        value={primaryPerson}
                                        onChange={(e) => setPrimaryPerson(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={`Primary Contact Person`}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <TextFieldsIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                          
                          
                          <Grid md={12} item>
                              <Typography className={classes.title} align="left" variant="body1">
                                  {t('company_details')}
                              </Typography>
                          </Grid>

                        {
                            isUserAdmin || isSuperAdmin
                                ? <>
                                 <Grid md={6} item>
                                    <TextField
                                        disabled={!isEditMode}
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t(`company_name`) + "*"}
                                        inputProps={{ maxLength: 100 }}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                              </Grid>
                              <Grid md={6} item>
                                  <TextField
                                      disabled={!isEditMode}
                                      value={companyTradingName}
                                      onChange={(e) => setCompanyTradingName(e.target.value)}
                                      variant="outlined"
                                      fullWidth
                                      label={t(`Company Trading Name`)}
                                      inputProps={{ maxLength: 100 }}
                                      InputLabelProps={{
                                          style: {
                                              fontWeight: 'bold'
                                          }
                                      }}
                                  />
                              </Grid>
                              <Grid md={6} item>
                                  <TextField
                                      disabled={!isEditMode}
                                      value={companyUEN}
                                      onChange={(e) => {
                                          const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
                                          setCompanyUEN(input);
                                      }}
                                      onKeyDown={(e) => {
                                          if (e.key === '-' || e.key === 'e') {
                                              e.preventDefault();
                                          }
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      type="number"
                                      label={t(`ABN/ACN`)}
                                      inputProps={{ maxLength: 11 }}
                                      InputLabelProps={{
                                          style: {
                                              fontWeight: 'bold'
                                          }
                                      }}
                                  />
                              </Grid>
                              <Grid md={6} item>
                                  <TextField
                                      disabled={!isEditMode}
                                      value={companyWebsite}
                                      onChange={(e) => setCompanyWebsite(e.target.value)}
                                      variant="outlined"
                                      fullWidth
                                      label={t(`Company Website`)}
                                      inputProps={{ maxLength: 100 }}
                                      InputLabelProps={{
                                          style: {
                                              fontWeight: 'bold'
                                          }
                                      }}
                                  />
                              </Grid>
                              <Grid md={6} item>
                                  <TextField
                                      disabled={!isEditMode}
                                      value={companyServiceProvde}
                                      onChange={(e) => setCompanyServiceProvide(e.target.value)}
                                      variant="outlined"
                                      fullWidth
                                      label={t(`Primary Service Provide`)}
                                      inputProps={{ maxLength: 150 }}
                                      InputLabelProps={{
                                          style: {
                                              fontWeight: 'bold'
                                          }
                                      }}
                                  />
                              </Grid>
                              <Grid md={6} item>
                                  <TextField
                                      disabled={!isEditMode}
                                      value={officeAddress}
                                      onChange={(e) => setOfficeAddress(e.target.value)}
                                      variant="outlined"
                                      fullWidth
                                      label={t('Registered Office Address') + "*"}
                                      inputProps={{ maxLength: 200 }}
                                      InputLabelProps={{
                                          style: {
                                              fontWeight: 'bold'
                                          }
                                      }}
                                  />
                              </Grid>
                              <Grid md={6} item>
                                  <TextField
                                      disabled={!isEditMode}
                                      value={streetNameAddress}
                                      onChange={(e) => setStreetNameAddress(e.target.value)}
                                      variant="outlined"
                                      fullWidth
                                      label={t('Postal Address (if different)')}
                                      inputProps={{ maxLength: 200 }}
                                      InputLabelProps={{
                                          style: {
                                              fontWeight: 'bold'
                                          }
                                      }}
                                  />
                              </Grid>
                              <Grid md={6} item>
                                  <TextField
                                      disabled={!isEditMode}
                                      value={city}
                                      onChange={(e) => setCity(e.target.value)}
                                      variant="outlined"
                                      fullWidth
                                      label={t('enter_suburb_or_city') + "*"}
                                      InputLabelProps={{
                                          style: {
                                              fontWeight: 'bold'
                                          }
                                      }}
                                  />
                              </Grid>
                                </>
                                : null

                        }

<                         Grid className={classes.gap} md={12} item>
                              {/* GAP */}
                          </Grid>

                          <Grid md={12} item>
                              <Typography className={classes.title} align="left" variant="body1">
                                  {`Service Regions/Areas Covered`}
                              </Typography>
                          </Grid>

                          {cleanedRegions.map((area: string, i: number) => (
                                <Grid key={i} md={(i % 2 === 0 ? 3 : 9)} item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={!isEditMode}
                                                checked={cleanedRegionsSelect.includes(area)}
                                                onChange={() => handleCheckboxChange(area)}
                                                color="primary"
                                            />
                                        }
                                        label={t(area)}
                                    />
                                </Grid>
                            ))}
                          
                          <Grid className={classes.gap} md={12} item>
                              {/* GAP */}
                          </Grid>

                            <Grid md={12} item>
                                <Typography className={classes.title} align="left" variant="body1">
                                    {`Account Details`}
                                </Typography>
                            </Grid>


                            <Grid md={6} item>
                              <TextField
                                  disabled={!isEditMode}
                                  value={accountName}
                                  onChange={(e) => setAccountName(e.target.value)}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{ maxLength: 50 }}
                                  label={t('provide_account_contact_name') + "*"}
                                  InputLabelProps={{
                                      style: {
                                          fontWeight: 'bold'
                                      }
                                  }}
                              />
                          </Grid>
                          <Grid md={6} item>
                              <TextField
                                  disabled={!isEditMode}
                                  value={accountPhone}
                                  onChange={(e) => {
                                      const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                      setAccountPhone(input);
                                  }}
                                  onKeyDown={(e) => {
                                      if (e.key === '-' || e.key === 'e') {
                                          e.preventDefault();
                                      }
                                  }}
                                  variant="outlined"
                                  fullWidth
                                  type="number"
                                  label={t('provide_account_contact_phone') + "*"}
                                  inputProps={{ maxLength: 15 }}
                                  InputLabelProps={{
                                      style: {
                                          fontWeight: 'bold'
                                      }
                                  }}
                              />
                          </Grid>
                          <Grid md={6} item>
                              <TextField
                                  disabled={!isEditMode}
                                  value={accountEmail}
                                  onChange={(e) => setAccountEmail(e.target.value)}
                                  variant="outlined"
                                  fullWidth
                                  label={t('provide_account_contact_email') + "*"}
                                  inputProps={{ maxLength: 100 }}
                                  InputLabelProps={{
                                      style: {
                                          fontWeight: 'bold'
                                      }
                                  }}
                              />
                          </Grid>


                            <Grid className={classes.gap} md={12} item>
                                {/* GAP */}
                            </Grid>

                            <Grid md={12} item>
                                <Typography className={classes.title} align="left" variant="body1">
                                    {`Operation Details`}
                                </Typography>
                            </Grid>


                          <Grid md={6} item>
                              <TextField
                                  disabled={!isEditMode}
                                  value={operationsName}
                                  onChange={(e) => setOperationsName(e.target.value)}
                                  variant="outlined"
                                  fullWidth
                                  label={t('provide_account_operations_name') + "*"}
                                  inputProps={{ maxLength: 50 }}
                                  InputLabelProps={{
                                      style: {
                                          fontWeight: 'bold'
                                      }
                                  }}
                              />
                          </Grid>
                          <Grid md={6} item>
                              <TextField
                                  disabled={!isEditMode}
                                  value={operationsPhone}
                                  onChange={(e) => {
                                      const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                      setOperationsPhone(input);
                                  }}
                                  onKeyDown={(e) => {
                                      if (e.key === '-' || e.key === 'e') {
                                          e.preventDefault();
                                      }
                                  }}
                                  variant="outlined"
                                  fullWidth
                                  type="number"
                                  label={t('provide_account_operations_phone') + "*"}
                                  inputProps={{ maxLength: 15 }}
                                  InputLabelProps={{
                                      style: {
                                          fontWeight: 'bold'
                                      }
                                  }}
                              />
                          </Grid>
                          <Grid md={6} item>
                              <TextField
                                  disabled={!isEditMode}
                                  value={operationsEmail}
                                  onChange={(e) => setOperationsEmail(e.target.value)}
                                  variant="outlined"
                                  fullWidth
                                  label={t('provide_account_operations_email') + "*"}
                                  inputProps={{ maxLength: 100 }}
                                  InputLabelProps={{
                                      style: {
                                          fontWeight: 'bold'
                                      }
                                  }}
                              />
                          </Grid>

                          <Grid md={12} item>
                              <Typography className={classes.title} align="left" variant="body1">
                                  {t('Warehousing Capabilities')}
                              </Typography>
                          </Grid>

                          <Grid md={6} item>
                              <TextField
                                  disabled={!isEditMode}
                                  value={warehoueLocation}
                                  onChange={(e) => setWareHouseLocation(e.target.value)}
                                  variant="outlined"
                                  fullWidth
                                  label={t('Locations Available')}
                                  inputProps={{ maxLength: 100 }}
                                  InputLabelProps={{
                                      style: {
                                          fontWeight: 'bold'
                                      }
                                  }}
                              />
                          </Grid>
                          <Grid md={6} item>
                            <TextField
                                value={totalWareHoueSpace}
                                disabled={!isEditMode}
                                onChange={(e) => {
                                    const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                    setTotalWareHoueSpace(input);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                type="number"
                                label={t('Total Warehouse Space (m2)')}
                                inputProps={{ maxLength: 10 }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>

                          <Grid md={6} item>
                              <FormControl variant="filled" className={classes.formControl}>
                                  <InputLabel style={{ fontWeight: 'bold' }}>{t('Types of Storage Offered')}</InputLabel>
                                  <Select
                                      value={country}
                                      onChange={(e) => {
                                          setSelectTypeStorage(e.target.value as string)
                                      }}
                                  >
                                      {/* <MenuItem value={''}>{t('Please Select')}</MenuItem> */}
                                      <MenuItem value={selectTypeStorage}>{t(selectTypeStorage)}</MenuItem>
                                  </Select>
                              </FormControl>
                          </Grid>

                          <Grid md={6} item>
                              <TextField
                                  disabled={!isEditMode}
                                  value={wareHoueServiceProvide}
                                  onChange={(e) => setWareHoueServiceProvide(e.target.value)}
                                  variant="outlined"
                                  fullWidth
                                  placeholder="e.g. Pick & Pack, Cross Docking"
                                  label={t('Value Added Services Provided')}
                                  inputProps={{ maxLength: 150 }}
                                  InputLabelProps={{
                                      style: {
                                          fontWeight: 'bold'
                                      }
                                  }}
                              />
                          </Grid>

                          
                          <Grid md={12} item>
                              <Typography className={classes.title} align="left" variant="body1">
                                  {t('Fleet Information')}
                              </Typography>
                          </Grid>


                          <Grid md={6} item>
                        <TextField
                            value={fleetNumberOfTruck}
                            disabled={!isEditMode}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 5);
                                setFleetNumberOfTruck(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            label={t('Number Of Trucks')}
                            type="number"
                            inputProps={{ maxLength: 5 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={fleetMaxLoadCap}
                            disabled={!isEditMode}
                            onChange={(e) => setFleetMaxLoadCap(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Maximum Load Capacity')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={fleetTrackSystem}
                            disabled={!isEditMode}
                            onChange={(e) => setFleetTrackSystem(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Tracking System Used')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>


                    <Grid md={6} item>
                        <TextField
                            value={certificationsAccreditations}
                            disabled={!isEditMode}
                            onChange={(e) => setCertificationsAccreditations(e.target.value)}
                            variant="outlined"
                            fullWidth
                            placeholder="e.g. NHVAS, HACCP, ISO"
                            label={t('Other Certifications And Accreditations')}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>



                        <Grid md={12} item>
                              <Typography className={classes.title} align="left" variant="body1">
                                  {t('Rate Structure and Pricing Information')}
                              </Typography>
                        </Grid>


                        <Grid md={6} item>
                        <TextField
                            value={rateReviewFrequency}
                            disabled={!isEditMode}
                            onChange={(e) => setRateReviewFrequency(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Rate Review Frequency')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            disabled={!isEditMode}
                            value={noticePeriodRatechanges}
                            onChange={(e) => setNoticePeriodRatechanges(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Notice Period For Rate Changes')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Payment Terms')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            disabled={!isEditMode}
                            value={paymentTerms}
                            onChange={(e) => setPaymentTerms(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Payment Terms')}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>


                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body2">
                            {t('Vendor Bank Account Details')}
                        </Typography>
                    </Grid>


                    <Grid md={6} item>
                        <TextField
                            value={vendorBankName}
                            disabled={!isEditMode}
                            onChange={(e) => setVendorBankName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Bank Name')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorBankBsb}
                            disabled={!isEditMode}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                                setVendorBankBsb(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            label={t('BSB')}
                            type="number"
                            inputProps={{ maxLength: 6 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorAccountNumber}
                            disabled={!isEditMode}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                setVendorAccountNumber(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t('Account Number') + "*"}
                            inputProps={{ maxLength: 15 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorBankAccountName}
                            disabled={!isEditMode}
                            onChange={(e) => setVendorBankAccountName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Account Name')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('MoveIt4u Software Application')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            disabled={!isEditMode}
                            value={moveItAdminName}
                            onChange={(e) => setMoveItAdminName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Admin Name')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={moveItAdminPosition}
                            disabled={!isEditMode}
                            onChange={(e) => setMoveItAdminPosition(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Admin Position/Title')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={moveItAdminEmail}
                            disabled={!isEditMode}
                            onChange={(e) => setMoveItAdminEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            label={t('Admin Email Address') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={moveItAdminPhone}
                            disabled={!isEditMode}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                setMoveItAdminPhone(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            label={t('Admin Phone')}
                            type="number"
                            inputProps={{ maxLength: 15 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Vendor Declaration')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorRepresentativeName}
                            disabled={!isEditMode}
                            onChange={(e) => setVendorRepresentativeName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Authorised Representative Name')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorRepresentativePosition}
                            disabled={!isEditMode}
                            onChange={(e) => setVendorRepresentativePosition(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Position/Title')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
    
                    <Grid md={6} item>
                        <CustomDatePicker
                            selectedDate={selectedDate}
                            handleDateChange={handleDateChange}
                            readOnly={!isEditMode}
                            placeholder="Select Date"
                            name="datePicker"
                            error={error}
                        />
                    </Grid>






                    </Grid>
                </DialogContent>
                {

                    !hideActions
                        ? <DialogActions>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item md={6}>
                                    {
                                        !isEditMode
                                            ? <Grid item md={3}>
                                                <Button onClick={handleOnDeleteClick} startIcon={<DeleteForeverIcon />} fullWidth className={classes.deletebtn} variant="contained">
                                                    {t('delete')}
                                                </Button>
                                            </Grid>
                                            : null
                                    }
                                </Grid>

                                <Grid item md={6}>
                                    <Grid
                                        spacing={2}
                                        container
                                        justifyContent="flex-end"
                                    >
                                        {
                                            !isEditMode
                                                ? <Grid item md={6}>
                                                    <Button onClick={handleUpdateStatusClick} className={classes.updatebtn} startIcon={userStatus === INACTIVE ? <CheckIcon /> : <ClearIcon />} variant="contained" fullWidth>{t(userStatus === INACTIVE ? 'mark_as_active' : 'mark_as_inactive')}</Button>
                                                </Grid>
                                                : null
                                        }
                                        {
                                            !isEditMode
                                                ? <Grid item md={6}>
                                                    <Button onClick={handleEditClick} startIcon={<EditIcon />} fullWidth className={classes.editBtn} variant="contained">
                                                        {t('edit')}
                                                    </Button>
                                                </Grid>
                                                : null
                                        }
                                        {
                                            isEditMode
                                                ? <Grid item md={6}>
                                                    <Button onClick={handleUpdateCarrierClick} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                                        {t('update')}
                                                    </Button>
                                                </Grid>
                                                : null
                                        }

                                        {
                                            isEditMode
                                                ? <Grid item md={6}>
                                                    <Button onClick={handleCancelClick} startIcon={<ClearIcon />} variant="contained" fullWidth>{t('cancel')}</Button>
                                                </Grid>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogActions>
                        : null
                }
            </Dialog>
        </>
    )
}


export default CarrierDetialsDialog