import makeStyles from "@material-ui/core/styles/makeStyles"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import { useTranslation } from "react-i18next"
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import React, { useCallback, useState } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom"
import { routes } from "../../../routes"
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import { COUNTRIES, COMPANY_TYPES, COUNTRY_INSURANCES, REGIONS_COUNTRIES, TRANSPORT_MODES, STORAGEOFFERED } from "../../../Types/Constants"
import { getCountryByAbbr, getLabelForRegistrationNumberByCountry } from "../../../Utilities/Country"
import { isNumeric } from "../../../Utilities/Regex"
import { Company } from "../../../Api/Company/Company"
import { toast } from "react-toastify"
import isEmail from 'validator/lib/isEmail';
import MapAutocomplete from '../../Common/MapAutocomplete/MapAutocomplete'
import MapAddress from "../../../BP/MapAddress"
import TermsOfServiceDialog from "../../Dialogs/TermsOfServiceDialog/TermsOfServiceDialog"
import RadioGroupComponent from "../../Common/RadioGroupComponent/RadioGroupComponent"
import FileInput from "../../Common/FileInput/FileInput"
import CustomDateTimePicker from "../../Home/Jobs/CreateNewJob/DatePickers"
import CustomDatePicker from "../../../ComponentsV2/ui/CustomDatePicker"
import TermsOfServiceDialogCarriers from "../../Dialogs/TermsOfServiceDialog/TermsOfServiceDialogCarriers"

const { FREIGHT_FORWARED, NVOCC, IMPORT_EXPORTER, SHIPPING_LINE, TRANSPORT_COMPANY } = COMPANY_TYPES

// const {NEWSOUTHWALES,VICTORIA,QUEENSLAND, SOUTHAUSTRALIA, WESTERNAUSTRALIA, TASMANIA, AUSTRAILIANCAPITALTERRITORY, NORTHENTERRITORY} = COUNTRIESREGIONS

const { AMBIENT, COLDSTOREHACCP } = STORAGEOFFERED

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const useStyles = makeStyles((theme) => ({
    companyRegisterBox: {
        backgroundColor: 'white',
        padding: '40px 15px',
        borderRadius: '20px',
        boxShadow: '2px 3px 6px -1px rgba(0,0,0,0.5)',
        overflowY: 'auto',
        height: '95vh',
    },
    logo: {
        width: '150px',
        height: '150px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    gap: {
        marginTop: theme.spacing(3)
    },
    btnRegister: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    btnBack: {
        backgroundColor: 'grey',
        color: 'white',
        '&:hover': {
            backgroundColor: 'grey'
        }
    },
    termsOfServiceBtn: {
        fontStyle: 'italic',
        background: 'none',
        border: 'none',
        padding: '0',
        textDecoration: 'underline',
        color: '#069',
        cursor: 'pointer'
    }
}))

const companyApi = new Company()

function CarrierRegister() {
    const history = useHistory()
    const classes = useStyles()
    const [t] = useTranslation('CarrierRegister')
    // const [t] = useTranslation('CompanyRegister')
    const [showPass, setShowPass] = useState<boolean>(false)
    const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false)
    const [country, setCountry] = useState<string>('')
    const [companyType, setCompanyType] = useState<string>('')
    const [carrierName, setCarrierName] = useState('')
    const [companyUen, setCompanyUen] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyTradingName, setCompanyTradingName] = useState('')
    const [companyWebsite, setCompanyWebsite] = useState('')
    const [companyServiceProvde, setCompanyServiceProvide] = useState('')
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [confirmMobile, setConfirmMobile] = useState('')
    const [primaryContact, setPrimaryContact] = useState('')
    const [title, setTitle] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [streetAndName, setStreetAndName] = useState('')
    const [companyPostalAddress, setCompanyPostalAddress] = useState('')
    const [suburbOrCity, setSuburbOrCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [insurances, setInsurances] = useState<string[]>([])
    const [countryRegions, setCountryRegions] = useState<any>([])
    const [modeofTransport, setTransportMode] = useState<any>([])
    const [accountName, setAccountName] = useState('')
    const [accountPhone, setAccountPhone] = useState('')
    const [accountEmail, setAccountEmail] = useState('')
    const [operationsName, setOperationsName] = useState('')
    const [operationsPhone, setOperationsPhone] = useState('')
    const [operationsEmail, setOperationsEmail] = useState('')
    const [warehoueLocation, setWareHouseLocation] = useState('')
    const [totalWareHoueSpace, setTotalWareHoueSpace] = useState('')
    const [wareHoueServiceProvide, setWareHoueServiceProvide] = useState('')
    const [fleetNumberOfTruck, setFleetNumberOfTruck] = useState('')
    const [fleetMaxLoadCap, setFleetMaxLoadCap] = useState('')
    const [fleetTrackSystem, setFleetTrackSystem] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [isDetailsForOperationsSame, setIsDetailsForOperationsSame] = useState(false)
    const [isDetailsForAccountSame, setIsDetailsForAccountSame] = useState(false)
    const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] = useState(false)
    const [waitingTime, setWaitingTime] = useState('');
    const [waitingTimeCharge, setWaitingTimeCharge] = useState('');
    const [agreedTos, setAgreedTos] = useState(false);
    const [importerName, setImporterName] = useState('')
    const [importerPhone, setImporterPhone] = useState('')
    const [importerEmail, setImporterEmail] = useState('')
    const [exporterName, setExporterName] = useState('')
    const [exporterPhone, setExporterPhone] = useState('')
    const [exporterEmail, setExporterEmail] = useState('')
    const [latitude, setLatitude] = useState<number | undefined>(undefined)
    const [longitude, setLongitude] = useState<number | undefined>(undefined)
    const [liabilityInsurance, setLiabilityInsurance] = useState('');
    const [compensationInsurance, setCompensationInsurance] = useState('');
    const [carriersLiabilityInsurance, setCarriersLiabilityInsurance] = useState('');
    const [responsibilitycorLegislation, setResponsibilitycorLegislation] = useState('');
    const [certificationsAccreditations, setCertificationsAccreditations] = useState('');
    const [vendorBankName, setVendorBankName] = useState('');
    const [vendorBankBsb, setVendorBankBsb] = useState('');
    const [vendorAccountNumber, setVendorAccountNumber] = useState('');
    const [vendorBankAccountName, setVendorBankAccountName] = useState('');
    const [serviceRegionsInternational, setServiceRegionsInternational] = useState('');
    const [moveItAdminName, setMoveItAdminName] = useState('');
    const [moveItAdminPosition, setMoveItAdminPosition] = useState('');
    const [moveItAdminEmail, setMoveItAdminEmail] = useState('');
    const [moveItAdminPhone, setMoveItAdminPhone] = useState('');
    const [vendorRepresentativeName, setVendorRepresentativeName] = useState('');
    const [vendorRepresentativePosition, setVendorRepresentativePosition] = useState('');
    const [vendorRepresentativeSignature, setVendorRepresentativeSignature] = useState<any>('');
    const [selectedDate, setSelectedDate] = useState('');
    const [error, setError] = useState('');
    const [uploadLibalityInsurance, setUploadLibalityInsurance] = useState<any>()
    const [compensationInsuranceFile, setCompensationInsuranceFile] = useState<any>()
    const [carrierLiabilityInsuranceFile, setCarrierLiabilityInsuranceFile] = useState<any>()
    const [responsibilityLegislationFile, setResponsibilityLegislationFile] = useState<any>()
    const [rateSheetAttachment, setRateSheetAttachment] = useState<any>()
    const [rateReviewFrequency, setRateReviewFrequency] = useState('');
    const [noticePeriodRatechanges, setNoticePeriodRatechanges] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');

    // console.log(email, confirmEmail,mobile,confirmMobile,firstName, lastName, password, passwordConfirm, streetAndName,suburbOrCity, '**************');

    const INSURANCES = COUNTRY_INSURANCES[country] ?? []

    const COUNTRYAREA = REGIONS_COUNTRIES?.new_south_wales ?? []

    const TransportModes = TRANSPORT_MODES?.road ?? []

    // Handle date change
    const handleDateChange = (event) => {
        const dateValue = event.target.value;
        setSelectedDate(dateValue);

        // Example validation
        if (!dateValue) {
            setError('Date is required');
        } else {
            setError('');
        }
    };

    const options = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ];

    const handleChangeLiabilityInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLiabilityInsurance(event.target.value);
    };

    const handleChangeCompensationInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompensationInsurance(event.target.value);
    };

    const handleChangeCarriersLiabilityInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCarriersLiabilityInsurance(event.target.value);
    };

    const handleChangeResponsibilitycorLegislation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResponsibilitycorLegislation(event.target.value);
    };


    const handleTermsOfServiceDialogClose = useCallback(
        () => {
            setIsTermsOfServiceDialogOpen(false)
        },
        [],
    )

    const handleBackButtonClick = () => {
        history.push(routes.landing.login[0])
    }

    const handleFileUploadInsurance = (e: any) => {
        setUploadLibalityInsurance(e.target.value)
    }
    const handleFileUploadCompensationInsurance = (e: any) => {
        setCompensationInsuranceFile(e.target.value)
    }

    const handleFileUploadCarrierLiabilityInsurance = (e: any) => {
        setCarrierLiabilityInsuranceFile(e.target.value)
    }

    const handleFileUploadResponsibilityLegislation = (e: any) => {
        setResponsibilityLegislationFile(e.target.value)
    }

    const handleFileUploadvendorRepresentativeSignature = (e: any) => {
        setVendorRepresentativeSignature(e.target.value)
    }

    const handleFileUploadRateSheetAttachment = (e: any) => {
        setRateSheetAttachment(e.target.value)
    }

    const handleRegisterButtonClick = () => {

        if (email === '' || confirmEmail === '') {
            toast.warning(t('email_not_empty'));
            return;
        }
        
        if (!isEmail(email)) {
            toast.warning(t('email_invalid_format'));
            return;
        }

        if (!isEmail(confirmEmail)) {
            toast.warning(t('Confirm email has invalid format'));
            return;
        }
        
        // Check if email and confirmEmail match exactly
        if (email.trim().toLowerCase() !== confirmEmail.trim().toLowerCase()) {
            toast.warning(t('email_not_match'));
            return;
        }        

        if (mobile === '' || confirmMobile === '') {
            toast.warning(t('mobile_not_empty'))
            return
        } else if (!isNumeric(mobile) || !isNumeric(confirmMobile)) {
            toast.warning(t('mobile_not_number'))
            return
        } else if (mobile !== confirmMobile) {
            toast.warning(t('mobile_not_match'))
            return
        }

        if (title === '') {
            toast.warning(t('Title must not be empty'))
            return
        }
        if (firstName === '') {
            toast.warning(t('firstname_not_empty'))
            return
        }

        if (lastName === '') {
            toast.warning(t('lastname_not_empty'))
            return
        }

        if (password === '') {
            toast.warning(t('password_not_null'))
            return
        } else if (passwordConfirm === '') {
            toast.warning(t('confirm_password_not_empty'))
            return
        }
        else if (password.length < 6) {
            toast.warning(t('invalid_password_length'))
            return
        } else if (password !== passwordConfirm) {
            toast.warning(t('password_did_not_match'))
            return
        }

        if (companyName === '') {
            toast.warning(t('Company name must not be empty'))
            return
        }

        if (streetAndName === '') {
            toast.warning(t('Registered office address must not be empty'))
            return
        }

        if (suburbOrCity === '') {
            toast.warning(t('suburb_city_not_empty'))
            return
        }

        // if (postalCode === '') {
        //     toast.warning(t('postal_code_not_empty'))
        //     return
        // } else if (!isNumeric(postalCode)) {
        //     toast.warning(t('postal_code_invalid'))
        //     return
        // }

        if (accountName === '') {
            toast.warning(t('Account contact name must not be empty'))
            return
        }

        if (accountPhone === '') {
            toast.warning(t('Account contact phone must not be empty'))
            return
        } else if (!isNumeric(accountPhone)) {
            toast.warning(t('Account contact phone invalid'))
            return
        }

        if (accountEmail === '') {
            toast.warning(t('Account contact email must not be empty'))
            return
        } else if (!isEmail(accountEmail)) {
            toast.warning(t('Account contact invalid email'))
            return
        }

        if (moveItAdminEmail === '') {
            toast.warning(t('Admin email must not be empty'))
            return
        } else if (!isEmail(moveItAdminEmail)) {
            toast.warning(t('Admin invalid email'))
            return
        }

        if (vendorAccountNumber === '') {
            toast.warning(t('Account number must not be empty'))
            return
        } else if (!isNumeric(vendorAccountNumber)) {
            toast.warning(t('Account number invalid'))
            return
        }

        // if (insurances.length < 4) {
        //     toast.warning(t('select_coverage'))
        //     return
        // }

        if (operationsName === '') {
            toast.warning(t('operation_name_not_empty'))
            return
        }

        if (operationsPhone === '') {
            toast.warning(t('operation_phone_not_empty'))
            return
        } else if (!isNumeric(operationsPhone)) {
            toast.warning(t('operation_phone_invalid'))
            return
        }

        if (operationsEmail === '') {
            toast.warning(t('operation_email_not_empty'))
            return
        } else if (!isEmail(operationsEmail)) {
            toast.warning(t('operation_invalid_email'))
            return
        }

        // if(companyType === TRANSPORT_COMPANY && (waitingTime !== '' || waitingTimeCharge !== '')) {
        //     if(!isNumeric(waitingTime)) {
        //         toast.warning(t('waiting_time_invalid'))
        //         return
        //     } else if(!isNumeric(waitingTimeCharge)) {
        //         toast.warning(t('waiting_time_charge_invalid'))
        //         return
        //     }

        // }

        if (!agreedTos) {
            toast.warning(t('tc_agree'))
            return
        }

        const formData = new FormData();
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('contact_email', email);
        formData.append('contact_phone', mobile);
        formData.append('password', password);
        formData.append('title', title);
        formData.append('directphonenumber', accountPhone);
        formData.append('accountcontactemail', accountEmail);
        formData.append('accountcontactname', accountName);
        formData.append('accountcontactphone', accountPhone);
        formData.append('operationscontactemail', operationsEmail);
        formData.append('operationscontactname', operationsName);
        formData.append('operationscontactphone', operationsPhone);
        formData.append('companyname', companyName);
        formData.append('companyabn', companyUen);
        formData.append('tradingname', companyTradingName);
        formData.append('companywebsite', companyWebsite);
        formData.append('addresscity', suburbOrCity);
        formData.append('addresspostalcode', postalCode);
        formData.append('addressstreet', streetAndName);
        formData.append('primaryservicesprovided', companyServiceProvde);
        formData.append('primarycontactperson', primaryContact);
        formData.append('warehouse_location', warehoueLocation);
        formData.append('total_warehouse_space', totalWareHoueSpace);
        formData.append('warehouse_services_provide', wareHoueServiceProvide);
        formData.append('fleet_number_of_trucks', fleetNumberOfTruck);
        formData.append('fleet_max_load', fleetMaxLoadCap);
        formData.append('fleet_tracking_system', fleetTrackSystem);
        formData.append('storage_types', country);
        formData.append('services', modeofTransport);
        formData.append('service_regions', countryRegions);
        formData.append('liability_insurance', liabilityInsurance);
        formData.append('liability_insurance_file', uploadLibalityInsurance); //file
        formData.append('compensation_insurance', compensationInsurance);
        formData.append('compensation_insurance_file', compensationInsuranceFile); //file
        formData.append('carriers_liability_insurance', carriersLiabilityInsurance);
        formData.append('carriers_liability_insurance_file', carrierLiabilityInsuranceFile); //file
        formData.append('responsibility_legislation', responsibilitycorLegislation);
        formData.append('responsibility_legislation_file', responsibilityLegislationFile); //file
        formData.append('certifications_accreditations', certificationsAccreditations);
        formData.append('vendor_bank_name', vendorBankName);
        formData.append('vendor_bank_bsb', vendorBankBsb);
        formData.append('vendor_account_number', vendorAccountNumber);
        formData.append('vendor_account_name', vendorBankAccountName);
        formData.append('service_regions_internation', serviceRegionsInternational);
        formData.append('moveit_admin_name', moveItAdminName);
        formData.append('moveit_admin_position', moveItAdminPosition);
        formData.append('moveit_admin_email', moveItAdminEmail);
        formData.append('moveit_admin_phone', moveItAdminPhone);
        formData.append('vendor_authorised_rep_name', vendorRepresentativeName);
        formData.append('vendor_authorised_rep_position', vendorRepresentativePosition);
        formData.append('vendor_signature', vendorRepresentativeSignature); //file
        formData.append('vendor_declaration_date', selectedDate);
        formData.append('company_postal_address', companyPostalAddress);
        formData.append('rate_sheet_attachment', rateSheetAttachment);
        formData.append('rate_review_frequency', rateReviewFrequency);
        formData.append('notice_period_for_rate_changes', noticePeriodRatechanges);
        formData.append('payment_terms', paymentTerms);


        companyApi.CarrierRegister(formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
            .then((response) => {
                toast.success(t(response?.data?.message || 'registration_success'))
                history.push(routes.landing.login[0])
            })
            .catch((e: any) => {
                const errorMessage = e.response?.data?.message || 'an_error_occurred_while_registering';
                toast.error(t(errorMessage));
            })
    }

    const handleMapAddressSelect = useCallback(
        (mapAddress: MapAddress) => {
            setCountry(getCountryByAbbr(mapAddress.country))
            setStreetAndName([mapAddress.streetNum, mapAddress.route].join(' '))
            // setSuburbOrCity(mapAddress.city)
            setPostalCode(mapAddress.postalCode)
            setLatitude(mapAddress.lat)
            setLongitude(mapAddress.long)
        },
        [],
    )

    const handleInsuranceCheck = useCallback(
        (isChecked: boolean, insurance: string) => {
            if (isChecked) {
                if (!insurances.includes(insurance)) {
                    insurances.push(insurance)
                    setInsurances([...insurances])
                }
            } else {
                setInsurances(insurances.filter(ins => ins !== insurance))
            }
        },
        [insurances],
    )

    const handleCountryCheck = useCallback(
        (isChecked: boolean, area: string) => {
            if (isChecked) {
                if (!countryRegions.includes(area)) {
                    countryRegions.push(area)
                    setCountryRegions([...countryRegions])
                }
            } else {
                setCountryRegions(countryRegions.filter(ins => ins !== area))
            }
        },
        [countryRegions],
    )

    const handleTransportCheck = useCallback(
        (isChecked: boolean, tramode: string) => {
            if (isChecked) {
                if (!modeofTransport.includes(tramode)) {
                    modeofTransport.push(tramode)
                    setTransportMode([...modeofTransport])
                }
            } else {
                setTransportMode(modeofTransport.filter(ins => ins !== tramode))
            }
        },
        [modeofTransport],
    )


    return (
        <Container maxWidth="lg">
            <TermsOfServiceDialogCarriers agreed={agreedTos} onCheckBoxCheck={(agree) => { setAgreedTos(agree) }} open={isTermsOfServiceDialogOpen} handleClose={handleTermsOfServiceDialogClose} country={country} />
            <Box className={classes.companyRegisterBox}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid md={12} item>
                        <Grid md={12} item>
                            <Typography align="center" variant="h4" component="h4">
                                {t('Carrier Registration ')}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* <Grid md={12} item>
                        <MapAutocomplete onAddressSelect={handleMapAddressSelect} />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={latitude}
                            variant="outlined"
                            fullWidth
                            label={t('latitude')}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <TextFieldsIcon />
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid> */}
                    {/* <Grid md={6} item>
                        <TextField
                            value={longitude}
                            variant="outlined"
                            fullWidth
                            label={t('longitude')}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <TextFieldsIcon />
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid> */}
                    {/* <Grid md={6} item>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_your_country')}</InputLabel>
                            <Select
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value as string)
                                    setInsurances([])
                                }}
                            > */}
                    {/* <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem> */}
                    {/* <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem> */}
                    {/* <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem> */}
                    {/* <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem> */}
                    {/* </Select>
                        </FormControl>
                    </Grid> */}
                    {/* <Grid md={6} item>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_company_type')}</InputLabel>
                            <Select
                                value={companyType}
                                onChange={(e) => setCompanyType(e.target.value as string)}
                            >
                                <MenuItem value={NVOCC}>{t(NVOCC)}</MenuItem>
                                <MenuItem value={FREIGHT_FORWARED}>{t(FREIGHT_FORWARED)}</MenuItem>
                                <MenuItem value={TRANSPORT_COMPANY}>{t(TRANSPORT_COMPANY)}</MenuItem>
                                <MenuItem value={IMPORT_EXPORTER}>{t(IMPORT_EXPORTER)}</MenuItem>
                                <MenuItem value={SHIPPING_LINE}>{t(SHIPPING_LINE)}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                    {/* <Grid md={6} item>
                        <TextField
                            value={carrierName}
                            onChange={(e) => setCarrierName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_carrier_name')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <TextFieldsIcon />
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid> */}

                    <Grid md={6} item>
                        <TextField
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t("provide_email_address") + "*"}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={confirmEmail}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('confirm_email_address') + "*"}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={mobile}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                setMobile(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t('provide_mobile_number') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={confirmMobile}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                setConfirmMobile(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t('confirm_mobile_number') + "*"}
                            inputProps={{ maxLength: 15 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Enter Title') + "*"}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_first_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_last_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_password') + "*"}
                            type={showPass ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPass(!showPass)}>
                                            {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('confirm_password') + "*"}
                            type={showConfirmPass ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowConfirmPass(!showConfirmPass)}>
                                            {!showConfirmPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={primaryContact}
                            onChange={(e) => setPrimaryContact(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Primary Contact Person')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>
                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('company_details')}
                        </Typography>
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t(`company_name`) + "*"}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={companyTradingName}
                            onChange={(e) => setCompanyTradingName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t(`Company Trading Name`)}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={companyUen}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
                                setCompanyUen(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t(`ABN/ACN`)}
                            inputProps={{ maxLength: 11 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={companyWebsite}
                            onChange={(e) => setCompanyWebsite(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t(`Company Website`)}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={companyServiceProvde}
                            onChange={(e) => setCompanyServiceProvide(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t(`Primary Service Provide`)}
                            inputProps={{ maxLength: 150 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={streetAndName}
                            onChange={(e) => setStreetAndName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Registered Office Address') + "*"}
                            inputProps={{ maxLength: 200 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={companyPostalAddress}
                            onChange={(e) => setCompanyPostalAddress(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Postal Address (if different)')}
                            inputProps={{ maxLength: 200 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={suburbOrCity}
                            onChange={(e) => setSuburbOrCity(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('enter_suburb_or_city') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Service Regions/Areas Covered')}
                        </Typography>
                    </Grid>

                    {COUNTRYAREA.map((area, i) => (
                        <Grid key={i} md={(i % 2) === 0 ? 3 : 9} item>
                            <FormControlLabel
                                control={<Checkbox checked={countryRegions.includes(area)} onChange={(e) => handleCountryCheck(e.target.checked, area)} color="primary" />}
                                label={t(area)}
                            />
                        </Grid>
                    ))}

                    <Grid md={6} item>
                        {countryRegions.includes("International") && (
                            <TextField
                                value={serviceRegionsInternational}
                                onChange={(e) => setServiceRegionsInternational(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('Please Specify')}
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        )}
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Primary Transport Modes')}
                        </Typography>
                    </Grid>

                    {TransportModes.map((tmode, i) => (
                        <Grid key={i} md={(i % 2) === 0 ? 3 : 9} item>
                            <FormControlLabel
                                control={<Checkbox checked={modeofTransport.includes(tmode)} onChange={(e) => handleTransportCheck(e.target.checked, tmode)} color="primary" />}
                                label={t(tmode)}
                            />
                        </Grid>
                    ))}

                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>

                    <Grid md={12} item>
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={isDetailsForAccountSame} onChange={(e) => {
                                if (e.target.checked) {
                                    setAccountName(`${firstName} ${lastName}`)
                                    setAccountEmail(email)
                                    setAccountPhone(mobile)
                                } else {
                                    setAccountName('')
                                    setAccountEmail('')
                                    setAccountPhone('')
                                }
                                setIsDetailsForAccountSame(e.target.checked)
                            }} />}
                            label={t('use_account_details')}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            label={t('provide_account_contact_name') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={accountPhone}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                setAccountPhone(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t('provide_account_contact_phone') + "*"}
                            inputProps={{ maxLength: 15 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={accountEmail}
                            onChange={(e) => setAccountEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_contact_email') + "*"}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                   
                    <Grid md={12} item>
                        <FormControlLabel
                            control={<Checkbox checked={isDetailsForOperationsSame} color="primary" onChange={(e) => {
                                if (e.target.checked) {
                                    setOperationsName(`${firstName} ${lastName}`)
                                    setOperationsEmail(email)
                                    setOperationsPhone(mobile)
                                } else {
                                    setOperationsName('')
                                    setOperationsEmail('')
                                    setOperationsPhone('')
                                }

                                setIsDetailsForOperationsSame(e.target.checked)
                            }} />}
                            label={t('use_operations_contact_details')}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={operationsName}
                            onChange={(e) => setOperationsName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_operations_name') + "*"}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={operationsPhone}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                setOperationsPhone(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t('provide_account_operations_phone') + "*"}
                            inputProps={{ maxLength: 15 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={operationsEmail}
                            onChange={(e) => setOperationsEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('provide_account_operations_email') + "*"}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Warehousing Capabilities')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={warehoueLocation}
                            onChange={(e) => setWareHouseLocation(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Locations Available')}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={totalWareHoueSpace}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                setTotalWareHoueSpace(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t('Total Warehouse Space (m2)')}
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{t('Types of Storage Offered')}</InputLabel>
                            <Select
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value as string)
                                    setInsurances([])
                                }}
                            >
                                <MenuItem value={''}>{t('Please Select')}</MenuItem>
                                <MenuItem value={AMBIENT}>{t(AMBIENT)}</MenuItem>
                                <MenuItem value={COLDSTOREHACCP}>{t(COLDSTOREHACCP)}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={wareHoueServiceProvide}
                            onChange={(e) => setWareHoueServiceProvide(e.target.value)}
                            variant="outlined"
                            fullWidth
                            placeholder="e.g. Pick & Pack, Cross Docking"
                            label={t('Value Added Services Provided')}
                            inputProps={{ maxLength: 150 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Fleet Information')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={fleetNumberOfTruck}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 5);
                                setFleetNumberOfTruck(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            label={t('Number Of Trucks')}
                            type="number"
                            inputProps={{ maxLength: 5 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={fleetMaxLoadCap}
                            onChange={(e) => setFleetMaxLoadCap(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Maximum Load Capacity')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={fleetTrackSystem}
                            onChange={(e) => setFleetTrackSystem(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Tracking System Used')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Insurance And Compliance')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <RadioGroupComponent
                            label="Do you have public liability insurance?"
                            name="liability-insurance"
                            value={liabilityInsurance}
                            onChange={handleChangeLiabilityInsurance}
                            options={options}
                        />

                        {liabilityInsurance === 'yes' &&
                            <FileInput
                                onChange={handleFileUploadInsurance}
                                label={t("Upload Insurance Certificate") + "*"}
                                multiple={false}
                                accept="image/*"
                            />
                        }
                    </Grid>

                    <Grid md={6} item>
                        <RadioGroupComponent
                            label="Do you have Workers’ Compensation Insurance?"
                            name="compensation-insurancee"
                            value={compensationInsurance}
                            onChange={handleChangeCompensationInsurance}
                            options={options}
                        />
                        {compensationInsurance === 'yes' &&
                            <FileInput
                                onChange={handleFileUploadCompensationInsurance}
                                label={t("Upload Compensation Insurance") + "*"}
                                multiple={false}
                                accept="image/*"
                            />
                        }
                    </Grid>

                    <Grid md={6} item>
                        <RadioGroupComponent
                            label="Do you have Carrier’s Liability Insurance?"
                            name="carriers-liability-insurance"
                            value={carriersLiabilityInsurance}
                            onChange={handleChangeCarriersLiabilityInsurance}
                            options={options}
                        />
                        {carriersLiabilityInsurance === 'yes' &&
                            <FileInput
                                onChange={handleFileUploadCarrierLiabilityInsurance}
                                label={t("Upload Carrier Liability Insurance") + "*"}
                                multiple={false}
                                accept="image/*"
                            />
                        }
                    </Grid>

                    <Grid md={6} item>
                        <RadioGroupComponent
                            label="Do you comply with Australian Chain of Responsibility (CoR) legislation?"
                            name="responsibility-cor-legislation"
                            value={responsibilitycorLegislation}
                            onChange={handleChangeResponsibilitycorLegislation}
                            options={options}
                        />
                        {responsibilitycorLegislation === 'yes' &&
                            <FileInput
                                onChange={handleFileUploadResponsibilityLegislation}
                                label={t("Upload Responsibility Legislation") + "*"}
                                multiple={false}
                                accept="image/*"
                            />
                        }
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={certificationsAccreditations}
                            onChange={(e) => setCertificationsAccreditations(e.target.value)}
                            variant="outlined"
                            fullWidth
                            placeholder="e.g. NHVAS, HACCP, ISO"
                            label={t('Other Certifications And Accreditations')}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Rate Structure and Pricing Information')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <Typography className={classes.title} align="left" variant="body2">
                            {t('Rate Sheet Attachment')}
                        </Typography>
                        <FileInput
                            onChange={handleFileUploadRateSheetAttachment}
                            label={t("Upload File") + "*"}
                            multiple={false}
                            accept="image/*"
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={rateReviewFrequency}
                            onChange={(e) => setRateReviewFrequency(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Rate Review Frequency')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={noticePeriodRatechanges}
                            onChange={(e) => setNoticePeriodRatechanges(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Notice Period For Rate Changes')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Payment Terms')}
                        </Typography>
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={paymentTerms}
                            onChange={(e) => setPaymentTerms(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Payment Terms')}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                   
                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body2">
                            {t('Vendor Bank Account Details')}
                        </Typography>
                    </Grid>


                    <Grid md={6} item>
                        <TextField
                            value={vendorBankName}
                            onChange={(e) => setVendorBankName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Bank Name')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorBankBsb}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                                setVendorBankBsb(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            label={t('BSB')}
                            type="number"
                            inputProps={{ maxLength: 6 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorAccountNumber}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                setVendorAccountNumber(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t('Account Number') + "*"}
                            inputProps={{ maxLength: 15 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorBankAccountName}
                            onChange={(e) => setVendorBankAccountName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Account Name')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('MoveIt4u Software Application')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={moveItAdminName}
                            onChange={(e) => setMoveItAdminName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Admin Name')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={moveItAdminPosition}
                            onChange={(e) => setMoveItAdminPosition(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Admin Position/Title')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={moveItAdminEmail}
                            onChange={(e) => setMoveItAdminEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            label={t('Admin Email Address') + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <TextField
                            value={moveItAdminPhone}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^0-9]/g, '').slice(0, 15);
                                setMoveItAdminPhone(input);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            label={t('Admin Phone')}
                            type="number"
                            inputProps={{ maxLength: 15 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                            {t('Vendor Declaration')}
                        </Typography>
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorRepresentativeName}
                            onChange={(e) => setVendorRepresentativeName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Authorised Representative Name')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>

                    <Grid md={6} item>
                        <TextField
                            value={vendorRepresentativePosition}
                            onChange={(e) => setVendorRepresentativePosition(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('Position/Title')}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid md={6} item>
                        <FileInput
                            onChange={handleFileUploadvendorRepresentativeSignature}
                            label={t("Upload Signature") + "*"}
                            multiple={false}
                            accept="image/*"
                        />
                    </Grid>
                    <Grid md={6} item>
                        <CustomDatePicker
                            selectedDate={selectedDate}
                            handleDateChange={handleDateChange}
                            readOnly={false}
                            placeholder="Select Date"
                            name="datePicker"
                            error={error}
                        />
                    </Grid>

                    <Grid md={11} item>
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('i_agree_to_tos')} <button onClick={() => setIsTermsOfServiceDialogOpen(true)} className={classes.termsOfServiceBtn}>{t('click_here_to_read_tos')}</button> {t('and_our')}
                            <a rel="noreferrer" href="https://moveit4u.com.au/privacypolicy/" target="_blank">{t('privacy_policy')}</a> {t('for_the_use_of_the_moveit4u_website_and_mobile_app')} <a rel="noreferrer" href="https://moveit4u.com.au/pricing/" target="_blank">{t('pricing_page')}</a>.
                        </Typography>
                    </Grid>
                    <Grid className={classes.gap} md={12} item>
                        {/* GAP */}
                    </Grid>
                    <Grid md={6} item>
                        <Button
                            startIcon={<DoneOutlineIcon />}
                            className={classes.btnRegister}
                            variant="contained"
                            fullWidth
                            onClick={handleRegisterButtonClick}
                        >
                            {t('register')}
                        </Button>
                    </Grid>
                    <Grid md={6} item>
                        <Button
                            startIcon={<ArrowBackIcon />}
                            className={classes.btnBack}
                            variant="contained"
                            fullWidth
                            onClick={handleBackButtonClick}
                        >
                            {t('back')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )

}


export default CarrierRegister