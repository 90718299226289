import axios from "axios"
import { TOKEN_STORAGE_KEY } from "../../Types/Constants"
import { WebStorage } from "../../Utilities/WebStorage"
import { ICarrierInfoPayload } from "../../../../Application/DTOs/CompanyDto/CompanyDto.type"

const baseUrl = process.env.REACT_APP_NEW_BASE_URL;

const storage = new WebStorage()

export class Carrier {
    getDetails(userId: string | number) {
        return axios.get(`${baseUrl}/v1/carrier/getCarrier/${userId}`, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateStatus(userId: string | number, payload: ICarrierInfoPayload) {
        return axios.post(`${baseUrl}/v1/carrier/status/update/status/${userId}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    update(userId: string | number, payload: ICarrierInfoPayload) {
        return axios.put(`${baseUrl}/v1/carrier/carrierUpdate/${userId}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

}