import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeader from '../../../Components/Common/SectionHeader/SectionHeader';
import CustomSearchInput from '../../ui/CustomSearchInput';
import { WebStorage } from '../../../Utilities/WebStorage';
import { TOKEN_STORAGE_KEY } from '../../../Types/Constants';
import useDebounce from '../../hooks/useDebounce';
import { Box, Button } from '@material-ui/core';
import { formatDateToMMDDYYYY, getTodayDate } from '../../lib/helper';
import { format } from 'date-fns';
import CustomDatePickerNew from '../../ui/CustomDatePickerNew';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 700,
        width: '100%',
        overflowX: 'auto',
    },
    searchContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(3),
    },
    dateContainer: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    button: {
        height: '100%',
        whiteSpace: 'nowrap',
        width: '80%',
    },
    dataGrid: {
        minWidth: "fit-content",
        '& .MuiDataGrid-menuIconButton': {
            color: 'white',
        },
    },

    errorMessage: {
        color: 'red',
        margin: '20px 0',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    }
}));

const storage = new WebStorage();

const AllJobsV2 = (props: any) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);
    const [data, setData] = useState({
        loading: true,
        totalRows: 0,
        rowsPerPageOptions: [5, 10, 20],
        page: 1,
        pageSize: 10,
        rows: [],
    });

    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchJobs();
    }, [debouncedSearch, fromDate, toDate]);

    const fetchJobs = async () => {
        setLoading(true);
        try {
            const myHeaders = new Headers({
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            });

            const formattedFromDate = fromDate ? format(new Date(fromDate), 'MM/dd/yyyy') : null;
            const formattedToDate = toDate ? format(new Date(toDate), 'MM/dd/yyyy') : null;

            const filters = JSON.stringify({ reference: debouncedSearch, createdDate: { "to": formattedToDate, "from": formattedFromDate } });
            const encodedFilters = encodeURIComponent(filters);

            const requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + `/v1/job/get/optimize?classification=transport&page=all&offset=0&filters=${encodedFilters}`,
                requestOptions
            );


            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const result = await response.json();

            if (!result?.jobList || result.jobList.length === 0) {
                setData((prevData) => ({
                    ...prevData,
                    rows: [],
                    totalRows: 0,
                }));
                return;
            }

            const formattedRows = result.jobList.map((job: any) => ({
                id: job.id,
                reference: job.reference,
                company: job.company?.name,
                customer_reference: job.customer_reference,
                pickup_location: job.job_addresses?.[0]?.pickupAddress
                    ? `${job.job_addresses[0].pickupAddress.company_name} - ${job.job_addresses[0].pickupAddress.street_number}, ${job.job_addresses[0].pickupAddress.city}, ${job.job_addresses[0].pickupAddress.state}, ${job.job_addresses[0].pickupAddress.postcode}`
                    : '',
                pickup_date: formatDateToMMDDYYYY(job.job_addresses[0]?.pickup_date_time?.split("T")[0]) ?? '',
                pickup_time: job.job_addresses[0]?.pickup_date_time?.split("T")[1]?.slice(0, 5) ?? '',
                delivery_location: job.job_addresses?.[job.job_addresses.length - 1]?.deliveryAddress
                    ? `${job.job_addresses[job.job_addresses.length - 1].deliveryAddress.company_name} - ${job.job_addresses[job.job_addresses.length - 1].deliveryAddress.street_number}, ${job.job_addresses[job.job_addresses.length - 1].deliveryAddress.city}, ${job.job_addresses[job.job_addresses.length - 1].deliveryAddress.state}, ${job.job_addresses[job.job_addresses.length - 1].deliveryAddress.postcode}`
                    : '',
                delivery_date: formatDateToMMDDYYYY(job.job_addresses[job.job_addresses.length - 1]?.delivery_date_time?.split("T")[0]) ?? '',
                delivery_time: job.job_addresses[job.job_addresses.length - 1]?.delivery_date_time?.split("T")[1]?.slice(0, 5) ?? '',
                movement_type_id: job.movement_type,
                status: job.status,
                created_at: formatDateToMMDDYYYY(job.created_at?.split("T")[0]),
            }));

            setData((prevData) => ({
                ...prevData,
                rows: formattedRows,
                totalRows: result.jobList.length,
            }));
        } catch (err) {
            console.error('Fetch error:', err);
            setData((prevData) => ({
                ...prevData,
                rows: [],
                totalRows: 0,
            }));
        } finally {
            setLoading(false);
        }
    };

    const columns: GridColDef[] = useMemo(() => [
        { field: 'reference', headerName: 'MI4U Reference', minWidth: 200, align: 'center', headerAlign: 'center' },
        { field: 'company', headerName: 'Company', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'customer_reference', headerName: 'Customer Reference', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'pickup_location', headerName: 'Pick-up Location', minWidth: 250, align: 'center', headerAlign: 'center' },
        { field: 'pickup_date', headerName: 'Pick-up Date', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'pickup_time', headerName: 'Pick-up Time', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'delivery_location', headerName: 'Delivery Location', minWidth: 250, align: 'center', headerAlign: 'center' },
        { field: 'delivery_date', headerName: 'Delivery Date', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'delivery_time', headerName: 'Delivery Time', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'movement_type_id', headerName: 'Movement Type', minWidth: 180, align: 'center', headerAlign: 'center' },
        { field: 'status', headerName: 'Status', minWidth: 150, align: 'center', headerAlign: 'center' },
        { field: 'created_at', headerName: 'Created At', align: 'center', headerAlign: 'center', minWidth: 150 },
        {
            field: 'actions', headerName: 'Actions', minWidth: 300, align: 'center', headerAlign: 'center', sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button
                        style={{ backgroundColor: '#1976d2', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}
                        onClick={() => console.log(params.row)}
                    >
                        Update
                    </button>
                    <button
                        style={{ backgroundColor: '#d32f2f', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}
                        onClick={() => console.log(params.row)}
                    >
                        Cancel
                    </button>
                    <button
                        style={{ backgroundColor: '#388e3c', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}
                        onClick={() => console.log(params.row)}
                    >
                        Download
                    </button>
                </div>
            )
        },
    ], []);

    const handleSearch = (query: string) => {
        setSearch(query);
    };

    const handleClearDates = useCallback(() => {
        if (fromDate || toDate || search) {
            setFromDate(null);
            setToDate(null);
            setSearch('');
        }
    }, [fromDate, toDate, search]);

    return (
        <>
            <SectionHeader subtitle="Transport Management" title="All Jobs" />
            <Box className={classes.searchContainer}>
                <Box className={classes.dateContainer}>
                    <CustomDatePickerNew
                        selectedDate={fromDate}
                        handleDateChange={(date: string) => setFromDate(date)}
                        maxDate={getTodayDate()}
                        name="fromDate"
                        placeholder="From"
                    />

                    <CustomDatePickerNew
                        selectedDate={toDate}
                        handleDateChange={(date: string) => setToDate(date)}
                        maxDate={getTodayDate()}
                        name="toDate"
                        placeholder="To"
                        minDate={fromDate}
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button}
                        onClick={handleClearDates}
                        disabled={!fromDate && !toDate && search === ''}
                    >
                        Clear Filters
                    </Button>
                </Box>
                <CustomSearchInput label="Search by reference" onSearch={handleSearch} variant="outlined" value={search} />
            </Box>
            {data?.rows?.length === 0 && !loading && <div className={classes.errorMessage}>No jobs found. </div>}
            <div className={classes.root}>
                <DataGrid
                    paginationMode="client"
                    sortingMode="client"
                    rows={data.rows}
                    columns={columns}
                    pageSize={data.pageSize}
                    rowsPerPageOptions={data.rowsPerPageOptions}
                    pagination
                    page={data.page - 1}
                    loading={loading}
                    disableSelectionOnClick
                    className={classes.dataGrid}
                    onRowClick={props.onRowClick}
                    autoHeight
                    rowCount={data.totalRows}
                    onPageChange={(newPage) => { setData((prev) => ({ ...prev, page: newPage + 1 })); }}
                    onPageSizeChange={(newPageSize) => {
                        setData((prev) => ({ ...prev, pageSize: newPageSize, page: 1 }));
                    }}
                />
            </div>
        </>
    );
};

export default AllJobsV2;
